// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
import "@fortawesome/fontawesome-free/js/all";
import "../stylesheets/application";

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(() => {
  $('input[type="checkbox"][data-toggle="toggle"]').bootstrapToggle();

  $("form").on("click", ".remove_fields", (event) => {
    const $fieldset = $(event.target).closest(".fieldset");
    if ($fieldset.parent().children(".fieldset:visible").length == 1) {
      return;
    }
    $fieldset.find("input[type=hidden].destroy").val("1");
    $fieldset.find('input').each((_, elm) => {
      elm.removeAttribute('required');
    });
    $fieldset.hide();
    event.preventDefault();
  });

  document.querySelectorAll('form .add_fields').forEach((elm) => {
    elm.onclick = (event) => {
      const time = new Date().getTime();
      const id = event.target.getAttribute('data-id')
      const regexp = new RegExp(id, "g");
      const to = event.target.getAttribute('data-to')
      const $to = document.querySelector(to)
      const fields = event.target.
        getAttribute('data-fields').
        replace(regexp, time).
        replace(/\${time}/g, time)
      $to.insertAdjacentHTML("beforeend", fields)
      event.preventDefault();
    }
  })

  $("form").on("change", "input[type=file]", (e) => {
    const $input = $(e.target);
    const $text = $input.parent().find("span");
    const $img = $input.parent().parent().find("img");
    let fileName = $input.val();
    if (fileName) {
      fileName = fileName.split("/").pop().split("\\").pop();
      if (fileName && $text) {
        $text.text(fileName);
      }
    }
    if ($img) {
      const oFReader = new FileReader();
      const file = $input[0].files[0];
      if (file) {
        oFReader.readAsDataURL(file);
        oFReader.onload = (e) => {
          $img.attr("src", e.target.result);
          $img.addClass("active");
        };
      }
    }
  });

  $(".autocomplete-exhibitor").select2({
    ajax: {
      url: "/exhibitors.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page,
        };
      },
      cache: true,
    },
    placeholder: "出展者を検索する",
    // minimumInputLength: 1,
  });

  $(".autocomplete-time").select2();

  if ($(".select-tag-ids")[0]) {
    $(".select-tag-ids")
      .select2({
        maximumSelectionLength: 3,
      })
      .val($(".select-tag-ids").data("values").split(","))
      .trigger("change");
  }

  $('input.toggle[name="booth[send_thanks_email]"][type="checkbox"]').on(
    "change",
    function () {
      if (!$(this).prop("checked")) {
        $('textarea[name="booth[thanks_email_attributes][body]"]').prop(
          "disabled",
          true
        );
      } else {
        $('textarea[name="booth[thanks_email_attributes][body]"]').prop(
          "disabled",
          false
        );
      }
    }
  );
});
